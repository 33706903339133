class Collapsible extends HTMLElement {
    constructor(){
        super();

        this.items = this.querySelectorAll('.collapse-item-header'); 
    }

    connectedCallback(){
        this.items.forEach( el => el.addEventListener('click', this.onClick.bind(this), false));
    }

    onClick(evnt){
        evnt.preventDefault();
        this._target = evnt.currentTarget.closest('.collapse-item');
        this.height = this._target.lastChild.previousSibling.scrollHeight;
        this.height = this.height + 56;

        if(this._target.classList.contains('active')){
            this._target.classList.remove('active')
            this._target.classList.remove('open')
            this._target.lastChild.previousSibling.style.cssText = 'height:'+ 0 +'px';
        } else {
            this._target.classList.add('open')
            this._target.classList.add('active')
            this._target.lastChild.previousSibling.style.cssText = 'height:auto;';
        } 
    }
}
customElements.define('collapsible-item', Collapsible);
